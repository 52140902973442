import $api from '@/helpers/config-axios'

import authHeader from './auth-header'
import store from '@/store'

class LogsService {
  async getLogs(list = false) {
    store.commit('other/onChangeLoadingItems', true)

    const {
      paginationPage,
      orderBy,
      sortedBy,
    } = store.state.logs

    const params = {
      page: paginationPage,
    }

    if (orderBy !== null) params['orderBy'] = orderBy
    if (sortedBy !== null) params['sortedBy'] = sortedBy

    return await $api
      .get('response-logs', {
        params,
        headers: authHeader(),
      }).then(async r => {
        await store.commit('logs/getAllElements', r.data)
        await store.commit('other/onChangeLoadingItems', false)
      })
  }

  getLog(id) {
    return $api
      .get(`response-logs/${id}`, {
        headers: authHeader(),
      })
  }
}

export default new LogsService()
