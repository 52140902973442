<template>
  <div>
    <SidebarLeft></SidebarLeft>

    <TableContainer
      ref="table-container"
      :columns="tableData"
      :counter-filter="this.$store.state.logs.searchCount"
      :is-archive="false"
      :is-archiving="false"
      :is-loading-items="$store.state.other.isLoadingItems"
      :is-loading-new-item="$store.state.other.isLoadingNewItem"
      :is-remove-from-archive="false"
      :rows="$store.state.logs.elements"
      :show-add-button="false"
      :sorting-fields="['id', 'created_at']"
      title="Лог ошибок"
      :is-filter="false"
      :is-show-search="false"
      name="logs"
      @update-data="getElements"
      @on-selected="selectItem"
    ></TableContainer>

    <SidebarRight :class="{'sidebar-right--show tw-w-[700px] tw-min-w-[700px]': sidebars.rightSidebar}">
      <LogDetails ref="log-details" />
    </SidebarRight>
  </div>
</template>

<script>
import SidebarRight from '../components/SidebarRight'
import TableContainer from '@/components/TableContainer'
import SidebarLeft from '@/components/SidebarLeft.vue'
import ZemButton from '@/components/ui/ZemButton.vue'
import LogsService from "@/services/logs.service";
import LogDetails from "@/components/LogDetails.vue";

export default {
  components: {
    LogDetails,
    ZemButton,
    SidebarLeft,
    SidebarRight,
    TableContainer,
  },

  data() {
    return {
      tableData: [],
    }
  },

  computed: {
    sidebars() {
      return this.$store.state.sidebars
    },
  },

  created() {
    this.tableData = [
      {
        label: 'id',
        field: 'id',
        sortField: 'id',
        showInTable: true,
        sort: 0,
      },
      {
        label: 'Дата ошибки',
        field: 'created_at',
        sortField: 'created_at',
        type: 'datetime',
        showInTable: true,
        sort: 2,
      },
      {
        label: 'Код ошибки',
        field: 'code',
        sortField: 'code',
        showInTable: true,
        sort: 0,
      },
      {
        label: 'Название ошибки',
        field: 'exception',
        sortField: 'exception',
        showInTable: true,
        sort: 0,
      },
      {
        label: 'Расшифровка ошибки',
        field: 'message',
        sortField: 'message',
        sort: 0,
        showInTable: true,
      },
      {
        label: 'Файл ошибки',
        field: 'file',
        sortField: 'file',
        sort: 0,
        showInTable: true,
      },
      {
        label: 'Line',
        field: 'line',
        sortField: 'line',
        sort: 0,
        showInTable: true,
      },
      // {
      //   label: 'Trace',
      //   field: 'trace',
      //   sortField: 'trace',
      //   sort: 0,
      //   showInTable: true,
      // },
      // {
      //   label: 'Request',
      //   field: 'request',
      //   sortField: 'request',
      //   sort: 0,
      //   showInTable: true,
      // },
    ]
  },

  mounted() {
    const {id} = this.$route.params
    this.$store.commit('sidebars/changeLeftSidebar', false)
    this.$store.commit('sidebars/changeRightSidebar', id !== undefined)
    this.getElements()
  },

  methods: {
    getElements() {
      LogsService.getLogs()
    },
    async selectItem(data) {
      if (data.column.field !== 'checkbox') await this.$store.commit('sidebars/changeRightSidebar', true)
      await this.$router.push({name: 'logs-id', params: {id: data.row.id}})
      this.$refs["log-details"].getData(data.row.id)
    },
    async showRightSidebar() {
      this.$store.commit('logs/itemsForDelete', [])
      this.$store.commit('sidebars/changeRightSidebar', true)
      if (this.$route.name !== 'logs') await this.$router.push({name: 'logs'})
    },
  }
}
</script>
